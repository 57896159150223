import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { mapState } from 'vuex'
import filters from '@/libs/filters'
import { newAxiosIns } from '@/libs/axios'
import { BSpinner } from 'bootstrap-vue'
import tab from '../komchat/tab.vue'
import komchatForBrands from '../komchat/komchat-for-brands.vue'

export default {
  components: {
    vSelect,
    BSpinner,
    tab,
    komchatForBrands,
  },
  filters: { rupiah: filters.rupiah },

  data() {
    return {
      expiredDate: 0,
      notification: {},
      pickupNotification: false,
      codNotification: false,
      codTooltip: 'Aktifkan Notifikasi',
      notificationTooltip: 'Aktifkan Notifikasi',
      messageTemplateCod: '',
      messageTemplatePickup: '',
      listFitur: [{ value: 1, name: 'Notifikasi WhatsApp Premium' }],
      listSubscription: [],
      subscription: {},
      fitur: { value: 1, name: 'Notifikasi WhatsApp Premium' },
      saldo_kompay: 0,
      disabledSubcription: true,
      loadingSubcription: false,
      isTemplate: true,
      lifetime: false,

      defaultWa: null,
      numbLogout: null,
      isKomchat: false,
      isScanKomchat: false,
      isScanKomship: false,
      listWAKomchat: [],
      isLoading: false,
      connected_wa: false,
      isKomchatUser: this.$store.state.dashboard.profile.is_komchat === 1,
      isKomshipUser: this.$store.state.dashboard.profile.is_komship === 1,
      currentPackage: '',
      currentLevelPacage: 0,
      tabActive: 'lite',
      modeTab: false,
    }
  },

  computed: {
    ...mapState('dashboard', ['profile']),
    ...mapState('whatsapp', ['phone_number', 'check_wa', 'list_message', 'dashboard_wa']),
  },

  async mounted() {
    this.getListWAKomchat()
    await this.$store.dispatch('whatsapp/getUpdateDashboardWa')
    this.currentPackage = this.$store.state.whatsapp.dashboard_wa.subscription_plan_category
    this.currentLevelPackage = this.$store.state.whatsapp.dashboard_wa.subscription_plan_level
    if (this.$route.params.modal === 'renew') {
      this.modalRenew()
    }
    this.checkWA()
  },

  methods: {
    handleTab(value) {
      this.tabActive = value
      this.modeTab = true
    },
    async checkWA() {
      this.defaultWa = this.check_wa.is_notification_whatsapp
      if (this.defaultWa !== 2) {
        this.$router.push({ name: 'opsional-feature' })
      } else {
        this.fetchMessages()
      }
    },
    async fetchMessages() {
      await this.$store.dispatch('whatsapp/getUpdateListMessage')
      this.codNotification = this.list_message.list_messages[1].status_message === 'active'
      this.pickupNotification = this.list_message.list_messages[0].status_message === 'active'
      if (this.list_message.list_messages[1].is_custom === 0) {
        this.messageTemplateCod = this.list_message.default_message.message_arrived_at
      } else {
        this.messageTemplateCod = this.list_message.list_messages[1].message
      }
      if (this.list_message.list_messages[0].is_custom === 0) {
        this.messageTemplatePickup = this.list_message.default_message.message_pickup
      } else {
        this.messageTemplatePickup = this.list_message.list_messages[0].message
      }

      if (this.codNotification === true) {
        this.codTooltip = 'Matikan Notifikasi'
      } else {
        this.codTooltip = 'Aktifkan Notifikasi'
      }

      if (this.pickupNotification === true) {
        this.notificationTooltip = 'Matikan Notifikasi'
      } else {
        this.notificationTooltip = 'Aktifkan Notifikasi'
      }
    },
    logout() {
      this.isLoading = true
      newAxiosIns.delete(`/komship//api/v1/whatsapp/delete/${this.numbLogout.id}`)
        .then(async response => {
          const { data } = response
          if (data.meta.code === 200) {
            this.$bvModal.hide('modal-logout')
            this.getListWAKomchat()
            this.$toast_success({ message: 'Berhasil menghapus nomor whatsapp' })
            this.isLoading = false
          }
        })
        .catch(() => {
          this.isLoading = false
          this.$toast_error({ message: 'Gagal menghapus nomor whatsapp' })
        })
    },
    async modalRenew() {
      await this.$http_komship
        .get('/v1/setting/notification-whatsapp-premium-fee')
        .then(response => {
          const { data } = response.data
          this.saldo_kompay = data.kompay_balance
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          })
        })
      await this.$http_komship
        .get('/v1/setting/renew-whatsapp-premium-fee')
        .then(response => {
          const { data } = response.data
          this.listSubscription = data
          this.subscription = { ...this.listSubscription[0] }
          this.subscriptionChange()
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          })
        })
      this.$bvModal.show('modal-renew-subcription')
    },
    subscriptionChange() {
      if (this.saldo_kompay < this.subscription.nominal) {
        this.disabledSubcription = true
      } else {
        this.disabledSubcription = false
      }
    },
    setTemplate(value) {
      let messageId = 0
      let toastSukses = ''
      let toastFailed = ''
      let statusMessagePickup = ''
      let statusMessageCod = ''
      const body = {}

      if (this.codNotification === true) {
        this.codTooltip = 'Matikan Notifikasi'
        statusMessageCod = 'active'
        this.isTemplate = true
      } else {
        this.codTooltip = 'Aktifkan Notifikasi'
        statusMessageCod = 'nonactive'
      }

      if (this.pickupNotification === true) {
        this.notificationTooltip = 'Matikan Notifikasi'
        statusMessagePickup = 'active'
        this.isTemplate = false
      } else {
        statusMessagePickup = 'nonactive'
        this.notificationTooltip = 'Aktifkan Notifikasi'
      }

      if (value === 'pickup') {
        messageId = this.list_message.list_messages[0].id
        body.status_message = statusMessagePickup
        toastSukses = 'Sukses mengaktifkan notifikasi Whatsapp Pickup'
        toastFailed = 'Gagal mengaktifkan notifikasi Whatsapp Pickup'
      } else {
        messageId = this.list_message.list_messages[1].id
        body.status_message = statusMessageCod
        toastSukses = 'Sukses mengaktifkan notifikasi Whatsapp Sampai di Kota tujuan'
        toastFailed = 'Gagal mengaktifkan notifikasi Whatsapp Sampai di Kota tujuan'
      }

      this.$http_komship
        .put(`/v2/wap/update-status-message/${messageId}`, body)
        .then(async res => {
          await this.$store.dispatch('whatsapp/getUpdateListMessage')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'AlertCircleIcon',
              text: toastSukses,
              variant: 'success',
            },
          }, 500)
        })
        .catch(() => {
          if (value === 'pickup') {
            this.pickupNotification = false
          } else {
            this.codNotification = false
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: toastFailed,
              variant: 'danger',
            },
          }, 500)
        })
    },
    hoverTemplate(template) {
      if (template === 'cod') {
        this.isTemplate = true
      } else {
        this.isTemplate = false
      }
    },
    setNumber(item) {
      if (this.isKomchatUser && this.isKomshipUser) { return }
      if (this.isKomchatUser) {
        this.setNumb(item, 'komchat')
      } else if (this.isKomshipUser) {
        this.setNumb(item, 'wap')
      }
    },
    setNumb(data, value) {
      if (value === 'delete') {
        this.$bvModal.show('modal-logout')
        this.numbLogout = data
      } else if (data.is_scan === true) {
        this.updateService(data, value)
      } else {
        this.$router.push({
          name: 'koneksi-wa',
          params: {
            number: data, type: value, scanWap: this.isScanKomship, scanKomchat: this.isScanKomchat,
          },
        })
      }
    },
    scanExisting(data, val) {
      this.$router.push({
        name: 'koneksi-wa',
        params: {
          number: data, type: val, scanWap: this.isScanKomship, scanKomchat: this.isScanKomchat,
        },
      })
    },
    async updateService(data, val) {
      await newAxiosIns.put(`/komship/api/v1/whatsapp/update-type/${data.id}`, {
        type: val,
      })
        .then(res => {
          this.getListWAKomchat()
          this.$toast_success({ message: 'Selamat, nomor kamu berhasil diupdate' })
        })
        .catch(err => {
          const resErr = err.response.data.meta
          if (resErr.message === 'failed, please reconnect') {
            this.scanExisting(data, val)
          } else {
            this.$toast_error({ message: resErr })
          }
        })
    },
    async getListWAKomchat() {
      this.isLoading = true
      await newAxiosIns.get('/komship/api/v1/whatsapp/list')
        .then(res => {
          const { data } = res.data
          this.listWAKomchat = data
          this.connected_wa = data.some(item => item.is_scan === true)
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.$toast_error({ message: 'Gagal mendapatkan list nomor Whatsapp' })
        })
    },
    redirectLog(value) {
      if (this.connected_wa) {
        this.$router.push({ name: 'riwayat-notifikasi', params: { card: value } })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: 'Tambahkan nomor whatsapp terlebih dahulu',
            variant: 'danger',
          },
        })
      }
    },
  },
}
